import axios from 'axios'

class Client {
    private url: string = "https://testrummi.azurewebsites.net/api";
    //private url: string = "https://localhost:5001/api";
    private userName: string = '';
    private token: string = '';
    private pushAvailable: boolean = false;

    public UpdateToken(userName: string, token: string) {
        this.userName = userName;
        this.token = token;
    }

    public PushAvailable() {
        return this.pushAvailable;
    }

    public SetPushAvailable(set: boolean) {
        this.pushAvailable = set;
    }
    
    public async AccountAuthenticate(request: Paths.ApiAccountAuthenticate.Post.RequestBody) : Promise<Paths.ApiAccountAuthenticate.Post.Responses.$200>{
        return this.ApiRequest('Account/Authenticate', request, false);
    }

    public async AccountNew(request: Paths.ApiAccountNew.Post.RequestBody) : Promise<Paths.ApiAccountNew.Post.Responses.$200>{
        return this.ApiRequest('Account/New', request, false);
    }

    public async AccountForgotPassword(request: Paths.ApiAccountForgotPassword.Post.RequestBody) : Promise<Paths.ApiAccountForgotPassword.Post.Responses.$200>{
        return this.ApiRequest('Account/ForgotPassword', request, false);
    }

    public GameGet(request: Paths.ApiGameGet.Post.RequestBody) : Promise<Paths.ApiGameGet.Post.Responses.$200> {
        return this.ApiRequest('Game/Get', request);
    }

    public GamePlay(request: Paths.ApiGamePlay.Post.RequestBody) : Promise<Paths.ApiGamePlay.Post.Responses.$200> {
        return this.ApiRequest('Game/Play', request);
    }

    public GamePass(request: Paths.ApiGamePass.Post.RequestBody) : Promise<Paths.ApiGamePass.Post.Responses.$200> {
        return this.ApiRequest('Game/Play', request);
    }

    public GameRemove(request: Paths.ApiGameRemove.Post.RequestBody) : Promise<Paths.ApiGameRemove.Post.Responses.$200> {
        return this.ApiRequest('Game/Remove', request);
    }

    public GameReject(request: Paths.ApiGameResign.Post.RequestBody) : Promise<Paths.ApiGameResign.Post.Responses.$200> {
        return this.ApiRequest('Game/Resign', request);
    }

    public InvitationSend(request: Paths.ApiInvitationSend.Post.RequestBody) : Promise<Paths.ApiInvitationSend.Post.Responses.$200> {
        return this.ApiRequest('Invitation/Send', request);
    }

    public InvitationAccept(request: Paths.ApiInvitationAccept.Post.RequestBody) : Promise<Paths.ApiInvitationAccept.Post.Responses.$200> {
        return this.ApiRequest('Invitation/Accept', request);
    }

    public InvitationReject(request: Paths.ApiInvitationReject.Post.RequestBody) : Promise<Paths.ApiInvitationReject.Post.Responses.$200> {
        return this.ApiRequest('Invitation/Reject', request);
    }

    public Invitation(request: Paths.ApiInvitationRemove.Post.RequestBody) : Promise<Paths.ApiInvitationRemove.Post.Responses.$200> {
        return this.ApiRequest('Invitation/Remove', request);
    }

    public FriendSearch(request: Paths.ApiFriendSearch.Post.RequestBody) : Promise<Paths.ApiFriendSearch.Post.Responses.$200> {
        return this.ApiRequest('Friend/Search', request);
    }

    public DeviceRegister(request: Paths.ApiDeviceRegister.Post.RequestBody) : Promise<Paths.ApiDeviceRegister.Post.Responses.$200> {
        return this.ApiRequest('Device/Register', request);
    }

    public async ApiRequest<T, U>(action: string, request: T, authorize = true): Promise<U> {
        try {
            console.info(`API ${action}`);
            const token = this.token;
            
            let result = await axios.post(`${this.url}/${action}`, request,
            {
                headers: authorize ? { Authorization: `Bearer ${token}` } : {}
            });
            console.info(`API ${action} ok: ${result.status}`);
            return result.data;
        } catch(error) {
            if (error.response?.data) {
                console.error(`API ${action} error: ${error.response.data}`);
                throw new ClientError(error.response.data);
            } else {
                console.log(error);
                console.error(`API ${action} error: unknown`);
                throw new ClientError({ Code: 9999, Message: 'unknown error', IsFunctional: false });
            }
        }    
    }
}

export const client = new Client();


export class ClientError extends Error {
    isFunctional: boolean | undefined = undefined;
    code: number | undefined = undefined;
    error: Components.Schemas.Error;

    constructor(error: Components.Schemas.Error) {
        super(error.Message?.toString());
        this.isFunctional = error.IsFunctional;
        this.code = error.Code;
        this.error = error;
    }
}