import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useDrop } from 'react-dnd'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: "1 0 0",
            height: 'auto',
            justifyContent: 'space-around',

            backgroundColor: '#ffffff',
            margin: '2px', 
            borderRadius: 3,
            border: 0,

            "&:before": {
                content:'""',
                float: 'left',
                paddingTop: '130%'
            }
        },
    }),
);

export interface DropProps {
    x: number,
    y: number,
    l: "Board" | "Plank"
}

export const Drop: React.FC<DropProps> = ({ x, y, l, children }) => {
    const classes = useStyles();

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'tile',
        drop: () => ({ x, y, l }),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
    });
    
    const isActive = canDrop && isOver;
    let backgroundColor = '#fff';
    if (isActive) {
        backgroundColor = '#eee';
    } else if (canDrop) {
        //backgroundColor = 'darkkhaki';
    }


    return (<div ref={drop} className={classes.root} style={{backgroundColor}}>{children}</div>);
}
