import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { client, ClientError } from "../../api/client";

const initialState = {
    error: '',
    loading: false,
    successfull: false
}

export const searchPlayerSlice = createSlice({
    name: 'searchplayer',
    initialState,
    reducers: {
        init: (state) => {
            state.error = '';
            state.loading = false;
            state.successfull = false;
        },
        pending: (state) => {
            state.error = '';
            state.loading = true;
            state.successfull = false;
        },
        error: (state, action) => {
            state.error = action.payload as string ?? '';
            state.loading = false;
            state.successfull = false;
        },
        success: (state, action) => {
            state.error = '';
            state.loading = false;
            state.successfull = true;
        },
    }
});

export function searchPlayer(playerName: string) {
    return async (dispatch: Dispatch) => {
        dispatch(searchPlayerSlice.actions.pending);
        try {
            if (!playerName) throw new Error("Player name required");

            const result = await client.FriendSearch({ SearchPlayerName: playerName});

            const result2 = await client.InvitationSend({ ToPlayerName: playerName});

            dispatch(searchPlayerSlice.actions.success({ player: result }));
        } catch (err) {
            if (err instanceof ClientError || err instanceof Error) {
                dispatch(searchPlayerSlice.actions.error(err.message));
            } else {
                dispatch(searchPlayerSlice.actions.error('Unhandled error'));
            }
        }
    };
}

