import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { gamesSlice } from '../features/games/gamesSlice';
import { usersSlice } from '../features/users/usersSlice';
import { searchPlayerSlice } from '../features/new/searchPlayerSlice'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfigUsers = {
  key: 'users',
  storage,
  whitelist: ['token', 'userName']
}

const persistConfigGames = {
  key: 'games',
  storage,
  whitelist: ['games', 'invitations']
}

export const store = configureStore({
  reducer: {
    users: persistReducer(persistConfigUsers, usersSlice.reducer),
    games: persistReducer(persistConfigGames, gamesSlice.reducer),
    searchPlayer: searchPlayerSlice.reducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["persist/PERSIST"],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();