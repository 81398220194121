import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { useAppDispatch } from "../../app/store";
import { gamesSlice } from "./gamesSlice";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.5em',
            borderRadius: 3,
        },
        red: {
            backgroundColor: 'red',
        },
        green: {
            backgroundColor: 'green',
        },
        blue: {
            backgroundColor: 'blue',
        },
        yellow: {
            backgroundColor: 'yellow',
        },
        isWrong: {
            border: '3px solid #00000077'
        }
    }),
);

export interface TileProps {
    tile: Components.Schemas.Tile; 
    isWrong: boolean;
    style: React.CSSProperties
}

export const Tile: React.FC<TileProps> = ({ tile, isWrong, style }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [{ opacity }, drag] = useDrag({
        item: { tile: tile, name: '' + tile.Id, type: 'tile' },
        end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
            const dropResult: { x: number, y: number, l: "Board" | "Plank" } = monitor.getDropResult()
            if (item && dropResult) {
                dispatch(gamesSlice.actions.move({ tileId: tile.Id ?? 0, x: dropResult.x, y: dropResult.y, l: dropResult.l }))
            }
        },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0 : 1,
        }),
    });

    const text = tile.Number === 0 ? 'J' : tile.Number;
    let color = classes.red;
    if (tile.Color === "Red")
        color = classes.red;
    else if (tile.Color === "Black")
        color = classes.green;
    else if (tile.Color === "Blue")
        color = classes.blue;
    else if (tile.Color === "Yellow")
        color = classes.yellow;
    
    let wrong = isWrong ? classes.isWrong : '';

    return (<div ref={drag} className={`${classes.root} ${color} ${wrong}`} style={{...style, opacity}}>{text}</div>);
}
