import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button, Container, CssBaseline } from '@material-ui/core';
import { useAppDispatch } from '../../app/store';
import { client } from '../../api/client';
import { useDispatch } from 'react-redux';
import { usersSlice } from '../users/usersSlice';


export interface SettingsProps {
}

export const SettingsPage: React.FC<SettingsProps> = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(usersSlice.actions.logout());
  };
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <h1>Settings</h1>
      <p>Build: {process.env.REACT_APP_BUILDNUMBER}</p>
      <p>Push: {client.PushAvailable() ? 'yes' : 'no'}</p>
      <Button color="primary" onClick={handleLogout}>Logout</Button>
    </Container>
  );
}
