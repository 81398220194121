import React, { FunctionComponent, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { BottomNavigation, BottomNavigationAction, Container, CssBaseline, IconButton, ListItemSecondaryAction, ListSubheader } from '@material-ui/core';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import SyncIcon from '@material-ui/icons/Sync';
import NewIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { gamesGetThunk, gamesSlice, invitationAcceptThunk, invitationRejectThunk } from './gamesSlice';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }),
);

export interface GameListProps {
}

export const GameListPage: FunctionComponent<GameListProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  
  const gamesState = useSelector((state: RootState) => state.games);
  
  useEffect(() => {
    dispatch(gamesGetThunk({}));
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue === "sync") {
      dispatch(gamesGetThunk({}));
    } else if (newValue === "settings") {
      history.push('/settings');      
    } else if (newValue === "new") {
      history.push('/new');      
    }
  };

  const handleInvitationAccept = async (invitationId: string) => {
    dispatch(invitationAcceptThunk({ InvitationId: invitationId  }));
  };

  const handleInvitationReject = async (invitationId: string) => {
    dispatch(invitationRejectThunk({ InvitationId: invitationId  }));
  };

  const handleClick = (gameId: string) => {
    dispatch(gamesSlice.actions.select(gameId));
    history.push('/game');      
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <List className={classes.root}>
        <ListSubheader>{`Your turn`}</ListSubheader>
        {gamesState.games.filter(g => g.Game.State === "YourTurn").map((game, i) => {
          return (
          <React.Fragment key={game.Id}>
            <ListItem alignItems="flex-start" button onClick={() => handleClick(game.Id ?? '')}>
              <ListItemAvatar>
                <Avatar alt={game.Game.Opponent?.Name ?? ''} src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={game.Game.Opponent?.Name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {game.Game.StatusMessage ?? ''}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;{game.Game.LastUpdateRelative?.TotalMinutes?.toFixed()} min ago
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider key={"divider" + game.Id} variant="inset" component="li" />
          </React.Fragment>
          ); 
        })}

        <ListSubheader>{`Their turn`}</ListSubheader>
        {gamesState.games.filter(g => g.Game.State === "TheirTurn").map((game, i) => {
          return (
          <React.Fragment key={game.Id}>
            <ListItem alignItems="flex-start" button onClick={() => handleClick(game.Id ?? '')}>
              <ListItemAvatar>
                <Avatar alt={game.Game.Opponent?.Name ?? ''} src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={game.Game.Opponent?.Name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {game.Game.StatusMessage ?? ''}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;{game.Game.LastUpdateRelative?.TotalMinutes?.toFixed()} min ago
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
          ); 
        })}

        <ListSubheader>{`Invitations`}</ListSubheader>
        {gamesState.invitations.map((invitation, i) => {
          return (
          <React.Fragment key={invitation.Id}>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={invitation.PlayerName ?? ''} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={invitation.PlayerName ?? ''}
              secondary={
                <React.Fragment>
                  {invitation.InvitationDateRelative?.TotalMinutes?.toFixed(0)} min ago
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="accept" onClick={handleInvitationAccept.bind(this, invitation.Id ?? '')}>
                <AddIcon />
              </IconButton>
              <IconButton edge="end" aria-label="reject" onClick={handleInvitationReject.bind(this, invitation.Id ?? '')}>
                <RemoveIcon />
              </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
          ); 
        })}
      </List>
      <BottomNavigation onChange={handleChange} className={classes.root}>
        <BottomNavigationAction label="Sync" value="sync" icon={<SyncIcon />} />
        <BottomNavigationAction label="New" value="new" icon={<NewIcon />} />
        <BottomNavigationAction label="Settings" value="settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Container>
  );
}
