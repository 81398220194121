import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { client, ClientError } from "../../api/client";

const initialState = {
    userName: '',
    token: '',
    refreshToken: '',
    error: '',
    loading: false,

    newError: '',
    newLoading: false,

    forgotPasswordError: '',
    forgotPasswordLoading: false
}

export function userLogin(userName: string, password: string) {
    return async (dispatch: Dispatch) => {
        dispatch(usersSlice.actions.loginPending);
        try {
            if (!userName) throw new Error("Player name required");
            if (!password) throw new Error("Password required"); 

            let result = await client.AccountAuthenticate({ UserName: userName, Password: password});

            // todo: refresh token toevoegen
            dispatch(usersSlice.actions.loginSuccess({ userName: userName, token: result.Token ?? '', refreshToken: '' }));
        } catch (err) {
            if (err instanceof ClientError || err instanceof Error) {
                dispatch(usersSlice.actions.loginError(err.message));
            } else {
                dispatch(usersSlice.actions.loginError('Unhandled error'));
            }
        }
    };
}

export function playerNew(playerName: string, password: string, email: string) {
    return async (dispatch: Dispatch) => {
        dispatch(usersSlice.actions.loginPending);
        try {
            if (!playerName) throw new Error("Player name required");
            if (!email) throw new Error("Email address required"); 
            if (!password) throw new Error("Password required"); 

            let result = await client.AccountNew({ UserName: playerName, Password: password, Email: email});

            if (result) {
                let result2 = await client.AccountAuthenticate({ UserName: playerName, Password: password });
                dispatch(usersSlice.actions.loginSuccess({ userName: playerName, token: result2.Token ?? '', refreshToken: '' }));
            } else {
                dispatch(usersSlice.actions.newError('Unable to create player'));
            }
        } catch (err) {
            if (err instanceof ClientError || err instanceof Error) {
                console.log(err);
                dispatch(usersSlice.actions.newError(err.message));
            } else {
                dispatch(usersSlice.actions.newError('Unhandled error'));
            }
        }
    };
}

export function forgotPassword(playerNameOrEmail: string) {
    return async (dispatch: Dispatch) => {
        dispatch(usersSlice.actions.forgotPasswordPending);
        try {
            if (!playerNameOrEmail) throw new Error("Player name or email address required");
            await client.AccountForgotPassword({ UserNameOrEmail: playerNameOrEmail });
            dispatch(usersSlice.actions.forgotPasswordSuccess());
        } catch (err) {
            if (err instanceof ClientError || err instanceof Error) {
                dispatch(usersSlice.actions.forgotPasswordError(err.message));
            } else {
                dispatch(usersSlice.actions.forgotPasswordError('Unhandled error'));
            }
        }
    };
}

export const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginPending: (state) => {
            state.userName = '';
            state.token = '';
            state.error = '';
            state.loading = true;
        },
        loginError: (state, action) => {
            state.userName = '';
            state.token = '';
            state.error = action.payload as string ?? '';
            state.loading = false;
        },
        loginSuccess: (state, action) => {
            state.userName = action.payload.userName;
            state.token = action.payload.token;
            state.error = '';
            state.loading = false;
            state.newError = '';
            state.newLoading = false;
        },

        newPending: (state) => {
            state.newError = '';
            state.newLoading = true;
        },
        newError: (state, action) => {
            state.newError = action.payload as string ?? '';
            state.newLoading = false;
        },

        forgotPasswordPending: (state) => {
            state.forgotPasswordError = '';
            state.forgotPasswordLoading = true;
        },
        forgotPasswordError: (state, action) => {
            state.forgotPasswordError = action.payload as string ?? '';
            state.forgotPasswordLoading = false;
        },
        forgotPasswordSuccess: (state) => {
            state.forgotPasswordError = '';
            state.forgotPasswordLoading = false;
        },
        
        logout: (state) => {
            state.userName = '';
            state.token = '';     
            state.error = '';     
            state.loading = false;
        }
    }
});
