import React, { useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { AppBar, Avatar, Box, Container, CssBaseline, IconButton, Toolbar } from '@material-ui/core';
import { RootState, useAppDispatch } from '../../app/store';
import PassIcon from '@material-ui/icons/Check';
import PlayIcon from '@material-ui/icons/PlayArrow';
import SortIcon from '@material-ui/icons/Sort';
import ReloadIcon from '@material-ui/icons/Replay';
import ChatIcon from '@material-ui/icons/Chat';
import { useSelector } from 'react-redux';
import { Tile } from './tile';
import { Drop } from './drop';
import { gamePassThunk, gamePlayThunk, gamesSlice } from './gamesSlice';
import { usePreview } from 'react-dnd-multi-backend';
import { useContainerDimensions } from '../../helpers/useContainerDimensions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    main: {
      padding: 0
    },
    inline: {
      display: 'inline',
    },
    appBar: {
      top: 'auto',
      bottom: 0
    },
    title: {
      float: 'right'
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center'
    },
    avatar: {
      padding: theme.spacing(1)
    }
  }),
);

export interface GameProps {
}

export const GamePage: React.FC<GameProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const gameState = useSelector((state: RootState) => state.games.games[state.games.selectedGameIndex]);
  const containerRef = useRef<HTMLElement>(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  function findTile(tiles: Components.Schemas.Tile[], x: number, y: number, l: string) {
    const tile = tiles.find(t => t.Group === x && t.Position === y && t.Location === l);
    if (tile) {
      const isWrong = gameState.WrongTileIds.find(x => x === tile.Id) != null;
      return <Tile tile={tile} isWrong={isWrong} style={{}}/>
    } else { 
      return;
    }
  }

  const handlePass = () => {
    if (gameState.HasChanged) {
      dispatch(gamePlayThunk({ GameId: gameState.Id, Tiles: gameState.TileState }));
    } else {
      dispatch(gamePassThunk({ GameId: gameState.Id, Tiles: gameState.TileState }));
    }
  }

  const handleSort = () => {
    if (gameState.Sort123) {
      dispatch(gamesSlice.actions.sort123());
    } else {
      dispatch(gamesSlice.actions.sort555());
    }
  }

  const handleReset = () => {
    dispatch(gamesSlice.actions.reset());
  }

  const TilePreview = (): JSX.Element | null => {
    const preview = usePreview<any>()
    if (!preview.display) {
      return null
    }
    const {style, item} = preview;

    style.width = containerWidth / 10;
    style.height = (containerWidth / 10) * 1.3;

    return <Tile tile={item.tile} isWrong={false} style={style}/>
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.main} ref={containerRef}>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {gameState.Game.State === "YourTurn" && (
          <>
          <IconButton color="inherit" onClick={handlePass}>
            {gameState.HasChanged ? <PlayIcon /> : <PassIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={handleSort}>
            <SortIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleReset}>
            <ReloadIcon />
          </IconButton>
          </>
          )}
          <IconButton color="inherit">
            <ChatIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        >
        <Box flex="0 0 0" className={classes.avatar}><Avatar alt={gameState.Game.Opponent.Name} src="/static/images/avatar/1.jpg"/></Box>
        <Box flex="1 0 0" justifyContent="center"><h4>{gameState.Game.StatusMessage}</h4></Box>
      </Box>

      {[...Array(10)].map((c, j) => 
        <Box 
          display="flex"
          flexWrap="nowrap"
          flexDirection="row"
          > 
          {[...Array(13)].map((c, i) => 
            <Drop x={i} y={j} l="Board">
              {findTile(gameState.TileState ?? [], j, i, "Board")}
            </Drop>
          )}
        </Box>
      )}

      <p></p>
      {[...Array(3)].map((c, j) => 
        <Box 
          display="flex"
          flexWrap="nowrap"
          flexDirection="row"
          > 
          {[...Array(10)].map((c, i) => 
            <Drop x={i} y={j} l="Plank">
              {findTile(gameState.TileState ?? [], j, i, "Plank")}
            </Drop>
          )}
        </Box>
      )}
    
    <TilePreview />

    </Container>
  );
}
