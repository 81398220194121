import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { client, ClientError } from "../../api/client";
import { createAsyncApiThunk } from "../../api/helper";
import { RootState } from "../../app/store";

export interface GamesState {
  selectedGameId: string;
  selectedGameIndex: number;
  games: GameState[],
  invitations: Components.Schemas.Invitation[];
  loading: boolean;
}

export interface GameState {
  Id: string,
  TileState: Components.Schemas.Tile[],
  HasChanged: boolean,
  Game: Components.Schemas.Game,
  Sort123: boolean;
  WrongTileIds: number[];
}

const initialState: GamesState = {
  selectedGameId: '',
  selectedGameIndex: 0,
  games: [],
  invitations: [],
  loading: false
}

export interface MoveAction {
  tileId: number;
  x: number;
  y: number;
  l: "Board" | "Plank";
}

export const gamesGetThunk = createAsyncThunk(
    "game/get",
    async (input: {}, thunkAPI) => {
        try {
            const s = thunkAPI.getState() as RootState;
            
            const request: Paths.ApiGameGet.Post.RequestBody = {
                GameStates: [],
                GameUpdates: s.games.games.map(g => { return { GameId: g.Id, LastUpdate: g.Game.LastUpdate }})
            };
            return await client.ApiRequest<Paths.ApiGameGet.Post.RequestBody, Paths.ApiGameGet.Post.Responses.$200>("game/get", request);
        } catch (err) {
            if (err instanceof ClientError) {
                return thunkAPI.rejectWithValue(err.error);
            }
            let error: Components.Schemas.Error = {
                Code: 9999,
                IsFunctional: false,
                Message: 'Unhandled error: ' + err, 
            };

            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const gamePassThunk = createAsyncApiThunk<Paths.ApiGamePass.Post.RequestBody, Paths.ApiGamePass.Post.Responses.$200>('game/pass');

export const gamePlayThunk = createAsyncApiThunk<Paths.ApiGamePlay.Post.RequestBody, Paths.ApiGamePlay.Post.Responses.$200>('game/play');

export const invitationSendThunk = createAsyncApiThunk<Paths.ApiInvitationSend.Post.RequestBody, Paths.ApiInvitationSend.Post.Responses.$200>('invitation/send');

export const invitationAcceptThunk = createAsyncApiThunk<Paths.ApiInvitationAccept.Post.RequestBody, Paths.ApiInvitationAccept.Post.Responses.$200>('invitation/accept');

export const invitationRejectThunk = createAsyncApiThunk<Paths.ApiInvitationReject.Post.RequestBody, Paths.ApiInvitationReject.Post.Responses.$200>('invitation/reject');

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
      clearState: (state) => {
          state.games = [];
          state.invitations = [];
          state.loading = false;
          state.selectedGameId = '';
          state.selectedGameIndex = 0;

      },
      select: (state, action: PayloadAction<string>) => {
        for(let i = 0; i < state.games.length; i++) {
          if (state.games[i].Id === action.payload) {
            state.selectedGameId = action.payload;
            state.selectedGameIndex = i;
          }
        }
      },
      move: (state, action: PayloadAction<MoveAction>) => {
        const gameState = state.games[state.selectedGameIndex];
        const tile = gameState.TileState.find(x => x.Id === action.payload.tileId);
        const otherTile = gameState.TileState.find(x => x.Group === action.payload.y && x.Position === action.payload.x && x.Location === action.payload.l);
        
        if (tile && !otherTile) {
          tile.Group = action.payload.y;
          tile.Position = action.payload.x;
          tile.Location = action.payload.l;
          gameState.HasChanged = TilesHasChanged(gameState);
          gameState.WrongTileIds = [];
        }
      },
      reset: (state, action: PayloadAction) => {
        const gameState = state.games[state.selectedGameIndex];
        gameState.HasChanged = false;
        gameState.TileState = CopyTiles(gameState.Game.Tiles);
        gameState.WrongTileIds = [];
      },
      sort123: (state, action: PayloadAction) => {
        const gameState = state.games[state.selectedGameIndex];
        const tileList:Components.Schemas.Tile[] = [];

        for(let tile of gameState.TileState.filter(t => t.Location === "Plank")) {
          let found = -1;
          let foundColor = false;
          for(let i = 0; i < tileList.length; i++) {
            const tileInList = tileList[i];
            if (tile.Color === tileInList.Color) {
              foundColor = true;
              if (tile.Number < tileInList.Number) {
                found = i;
                break;
              }
            } else if (foundColor) {
              found = i;
              break;
            }
          }

          if (found >= 0) {
            tileList.splice(found, 0, tile);
          } else {
            tileList.push(tile);
          }
        }

        let countx = 0;
        let county = 0;
        for(let tile of tileList) {
          tile.Position = countx;
          tile.Group = county;
          countx++;
          if (countx % 10 === 0)
          {
            county++;
            countx = 0;
          }
        }
        gameState.Sort123 = false;
        gameState.WrongTileIds = [];
      },
      sort555: (state, action: PayloadAction) => {
        const gameState = state.games[state.selectedGameIndex];
        const tileList:Components.Schemas.Tile[] = [];

        for(let tile of gameState.TileState.filter(t => t.Location === "Plank")) {
          let found = -1;
          for(let i = 0; i < tileList.length; i++) {
            const tileInList = tileList[i];
            if (tile.Number < tileInList.Number) {
              found = i;
              break;
            }
          }

          if (found >= 0) {
            tileList.splice(found, 0, tile);
          } else {
            tileList.push(tile);
          }
        }

        let countx = 0;
        let county = 0;
        for(let tile of tileList) {
          tile.Position = countx;
          tile.Group = county;
          countx++;
          if (countx % 10 === 0)
          {
            county++;
            countx = 0;
          }
        }
        gameState.Sort123 = true;
        gameState.WrongTileIds = [];
      }
    },
    extraReducers:  (builder) => {
        builder.addCase(gamePassThunk.fulfilled, (state, action) => {
          UpdateGame(action.payload, state.games);
        });

        builder.addCase(gamePlayThunk.rejected, (state, action) => {
          const gameState = state.games[state.selectedGameIndex];
          const error = action.payload as Components.Schemas.Error;
          if (error) {
            if (error.IsFunctional) {
              if (error.Code === 1207 || error.Code === 1212) {
                const reg = /\[(.*?)\]/g;
                const result = reg.exec(error.Message);
                if (result) {
                  gameState.WrongTileIds = result[1].split(',').map(x => parseInt(x));
                }
              }
            }
          }
        });

        builder.addCase(gamePlayThunk.fulfilled, (state, action) => {
          UpdateGame(action.payload, state.games);
        });
      
        builder.addCase(gamesGetThunk.pending, (state, action) => {
          state.loading = true;
        });

        builder.addCase(gamesGetThunk.fulfilled, (state, action) => {
          for(let game of action.payload.Games) {
            UpdateGame(game, state.games);
          }

          const removeIndexList = [];
          for(let removedGameId of action.payload.RemovedGames) {
            for(let i = 0; i < state.games.length; i++) {
              if (state.games[i].Id === removedGameId) {
                removeIndexList.push(i);
                break;
              }
            }          
          }

          for(let removeIndex of removeIndexList.sort().reverse()) {
            state.games.splice(removeIndex, 1);
          }

          state.invitations = action.payload.InvitationsFromPlayers;
          state.loading = false;
        });
    }
});

function UpdateGame(game: Components.Schemas.Game, gamesState: GameState[]) {
  let found = false;
  let newGameState = {
    Id: game.Id,
    TileState: CopyTiles(game.Tiles),
    HasChanged: false,
    Game: game,
    Sort123: true,
    WrongTileIds: []
  };

  for(let i = 0; i < gamesState.length; i++) {
    if (gamesState[i].Id === game.Id) {
      if (gamesState[i].Game.LastUpdate !== game.LastUpdate) {
        gamesState[i] = newGameState;
      }
      found = true;
      break;
    }
  }
  if (!found) {
    gamesState.push(newGameState);
  }
}

function CopyTiles(tiles: Components.Schemas.Tile[]): Components.Schemas.Tile[] {
  return tiles.map(x => {
    return {
      Id: x.Id,
      Position: x.Position,
      Group: x.Group,
      Color: x.Color,
      Number: x.Number,
      Location: x.Location
    }
  });
}

function TilesHasChanged(gameState: GameState): boolean {
  let hasChanged = false;

  for(let tile of gameState.Game.Tiles) {
    let tileState = gameState.TileState.find(t => t.Id === tile.Id && 
      ((t.Location === "Board" && tile.Location === "Board" && t.Group === tile.Group && t.Position === tile.Position) || (t.Location === "Plank" && tile.Location === "Plank")));
    
    if (!tileState) {
      hasChanged = true;
      break;
    }
  }

  return hasChanged;
}