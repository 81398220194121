import React, { useEffect, useState } from 'react';
import { Avatar, Button, CircularProgress, Container, CssBaseline, makeStyles, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { searchPlayer, searchPlayerSlice } from './searchPlayerSlice';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const SearchPlayerPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [playerNameText, setPlayerNameText] = useState<string>('');
  const { loading, error, successfull } = useSelector((state: RootState) => state.searchPlayer);

  useEffect(() => {
    dispatch(searchPlayerSlice.actions.init());
  }, []);

  const handlePlayerNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlayerNameText(event.target.value);
    },
    [setPlayerNameText]
  );

  const handleSeach = React.useCallback(
    () => {
      dispatch(searchPlayer(playerNameText));
    },
    [playerNameText, dispatch]
  );

  if (successfull) {
    return <Redirect to="/" />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Search player
        </Typography>
        <form className={classes.form} noValidate>
        <TextField
            error={error !== ''}
            helperText={error}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="playername"
            label="Player name"
            name="playername"
            autoComplete="playername"
            onChange={handlePlayerNameChange}
            value={playerNameText}
            autoFocus
          />
        <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={
              (e) => { e.preventDefault(); handleSeach(); }
            }
          >
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            Invite
          </Button>
          </form>
      </div>
    </Container>
  );
}
