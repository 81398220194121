import React from 'react';
import { Button, Container, CssBaseline } from '@material-ui/core';
import { useAppDispatch } from '../../app/store';
import { useHistory } from 'react-router-dom';

export interface NewProps {
}

export const NewPage: React.FC<NewProps> = () => {
  const history = useHistory();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <h1>New game</h1>
      <Button color="primary" onClick={() => history.push('/searchplayer')}>Search player</Button>
    </Container>
  );
}
