import React from 'react';
import { useSelector } from 'react-redux';
import { GameListPage } from '../features/games/gameListPage';
import { RootState, useAppDispatch } from './store';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { NewPage } from '../features/new/newPage';
import { SettingsPage } from '../features/settings/settingsPage';
import { GamePage } from '../features/games/gamePage';
import { NewPlayerPage } from '../features/users/newPlayerPage';
import { SignIn } from '../features/users/signInPage';
import { ForgotPasswordPage } from '../features/users/forgotPasswordPage';
import { SearchPlayerPage } from '../features/new/searchPlayerPage';
import { client } from '../api/client';
import { gamesGetThunk } from '../features/games/gamesSlice';

//https://github.com/rokoroku/react-redux-typescript-boilerplate 

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userName, token } = useSelector((state: RootState) => state.users);
  const isLoggedIn = token !== '';

  if (token !== '') {
    client.UpdateToken(userName, token);

    navigator.serviceWorker.ready.then((registration) => {
      const channel = new BroadcastChannel('sw-messages');
      channel.addEventListener('message', event => {
        if (event.data.type === 'update') {
          dispatch(gamesGetThunk({}));
        }
      });

      // Check if the user has an existing subscription
      return registration.pushManager.getSubscription()
        .then(function (subscription) {
          if (subscription) {
              return subscription;
          } else {
            const vapidPublicKey = "BAV944aUYdHpvJ4F3m4twHl86uAvrYjhJKzbwZf878C2-xPHMtSZ5YJevLU0EDbr6DMsGmguHSgu3tRBMDyqYyU";             
            return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
            });
          }
        })
        .then((result) => {
          const subscription = JSON.parse(JSON.stringify(result));
          const uri = 'p256dh***' + subscription.keys.p256dh + '###auth***' + subscription.keys.auth;

          client.DeviceRegister({UserName: userName, UniqueDeviceId: result.endpoint, PushNotificationUri: uri, OperatingSystem: 'web', AppVersion: 'x', Culture: 'x', DeviceType: 'x', OperatingSystemVersion: 'x' })
            .then(() => {
              client.SetPushAvailable(true);
            })
        })
        .catch((error) => {
          client.SetPushAvailable(false);
          console.log('Push notifications are not available')
          console.log(error);
        });
    });
  }

  if (isLoggedIn) {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={GameListPage} /> 
          <Route path="/game" component={GamePage} /> 
          <Route path="/settings" component={SettingsPage} /> 
          <Route path="/new" component={NewPage} /> 
          <Route path="/searchplayer" component={SearchPlayerPage} /> 
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route path="/new" component={NewPlayerPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

export default App;

// Utility function for browser interoperability
function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
      
  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);
  
  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
