import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, ClientError } from "./client";

export function createAsyncApiThunk<T, U>(action: string) {
  return createAsyncThunk(
    action,
    async (input: T, thunkAPI) => {
        try {
          return await client.ApiRequest<T, U>(action, input);
        } catch (err) {
          if (err instanceof ClientError) {
            return thunkAPI.rejectWithValue(err.error);
          }
          let error: Components.Schemas.Error = {
            Code: 9999,
            IsFunctional: false,
            Message: 'Unhandled error: ' + err, 
          };

          return thunkAPI.rejectWithValue(error);
        }
    }
  );
}