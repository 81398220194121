import React, { FunctionComponent, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { forgotPassword, playerNew, userLogin } from './usersSlice';
import { RootState } from '../../app/store';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { gamesSlice } from '../games/gamesSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ForgotPasswordPage: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [playerNameOrEmailText, setPlayerNameOrEmailText] = useState<string>('');
  const { newLoading, newError } = useSelector((state: RootState) => state.users);

  React.useEffect(() => {
    dispatch(gamesSlice.actions.clearState());
  });

  const handlePlayerNameOrEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlayerNameOrEmailText(event.target.value);
    },
    [setPlayerNameOrEmailText]
  );

  const handleSave = React.useCallback(
    () => {
      dispatch(forgotPassword(playerNameOrEmailText));
      history.push('/');
    },
    [playerNameOrEmailText, dispatch]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          New player
        </Typography>
        <form className={classes.form} noValidate>
        <TextField
            error={newError !== ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="playername"
            label="Player name or email"
            name="playername"
            autoComplete="playername"
            onChange={handlePlayerNameOrEmailChange}
            value={playerNameOrEmailText}
            autoFocus
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={newLoading}
            onClick={
              (e) => { e.preventDefault(); handleSave(); }
            }
          >
            {newLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            Send password reset request
          </Button>
        </form>
      </div>
    </Container>
  );
}
